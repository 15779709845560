@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

.header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  position: sticky;
  top: 0px;
  z-index: 20;
  background-color: #6B6B6B;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.7);
}

.header__logo {
  padding: 0px 50px;
  max-height: 80px;
  justify-self: flex-start;
  cursor: pointer;
}

@media (max-width: 700px) {
  .header__logo {
    padding: 0px 10px;
  }
}

.header__content {
  display: flex;
  align-items: center;
  margin-right: 50px;
  height: 80px;
}

.header__content__burger {
  display: none;
}

@media (max-width: 1024px) {
  .header__content__burger {
    display: block;
  }
}

.header__content__nav {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: flex-start;
}

@media (max-width: 1024px) {
  .header__content__nav {
    display: none;
  }
}

.header__content__nav__a {
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  font-size: clamp(9px, calc(0px + 0.9vw), 16px);
  color: #fff;
  padding-top: 31px;
  margin-right: 40px;
  text-align: center;
}

.header__content__nav__a:hover {
  border-top: 5px solid #F7D939;
  text-transform: uppercase;
  font-family: 'main';
  font-weight: 400;
  color: #fff;
  padding-top: 26px;
  text-decoration: none;
  height: 54px;
}

.header__content__button {
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin: 10px;
  transition: all 0.5s ease-out;
}

.header__content__button:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}

.header__content__button__hover {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #474747;
  background-color: #F7D939;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  font-size: 14px;
  padding: 5px 10px;
}

.header__content__button__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

@media (max-width: 700px) {
  .header__content__button__hover {
    padding: 5px 10px;
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .header__content__button__hover {
    font-size: 12px;
    min-height: 35px;
  }
}

@media (max-width: 374px) {
  .header__content__button {
    display: none;
  }
}

.header__content__nav__dropdown {
  position: relative;
  display: none;
}

.active .header__content__nav__dropdown-content {
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: absolute;
  top: 100%;
  right: 50px;
  background-color: #6B6B6B;
  padding: 10px;
  z-index: 1;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.7);
}

@media (min-width: 1024px) {
  .active .header__content__nav__dropdown-content {
    display: none;
  }
}

.active .header__content__nav__dropdown-content__a {
  padding: 10px 20px;
  text-decoration: none;
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.active .header__content__nav__dropdown-content__a:hover {
  color: #F7D939;
}

.header__content__nav__dropdown-content {
  display: none;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

.main {
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
}

.main__up {
  position: fixed;
  right: 20px;
  bottom: 100px;
  width: 65px;
  z-index: 100;
  cursor: pointer;
}

.main__choice__modal__layout {
  border-radius: 30px;
  max-width: 90vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main__choice__modal__layout__content {
  position: relative;
  width: fit-content;
  max-width: 70vw;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

@media (max-width: 768px) {
  .main__choice__modal__layout__content {
    max-width: 90vw;
  }
}

.main__choice__modal__layout__content__title {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  color: #474747;
}

@media (max-width: 670px) {
  .main__choice__modal__layout__content__title {
    font-size: 20px;
  }
}

@media (max-width: 550px) {
  .main__choice__modal__layout__content__title {
    font-size: 18px;
    padding: 0px 15px;
  }
}

.main__choice__modal__layout__content__a {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #474747;
  text-decoration: none;
  text-align: center;
  margin-bottom: 10px;
  transition: all 0.5s ease-out;
}

.main__choice__modal__layout__content__a:hover {
  text-decoration: underline;
  color: #c18f0d;
  transition: all 0.5s ease-out;
}

.main__section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: fit-content;
  max-height: fit-content;
  min-width: 100vw;
  max-width: 100vw;
  overflow: hidden;
}

.swiper-slide-shadow {
  display: none !important;
}

.swiper-button-prev {
  content: url(../img/arrow_left.svg);
  left: 0px !important;
}

.swiper-button-next {
  content: url(../img/arrow_right.svg);
  right: 0px !important;
}

.swiper-pagination-bullet {
  background-color: rgba(35, 32, 32, 0.3) !important;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.swiper-pagination-bullet-active {
  background-color: #FFCB03 !important;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.hystmodal__close:focus {
  outline: none !important;
}

.hystmodal__window {
  background-color: #ffffff00 !important;
  width: 100% !important;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

.main__section__home {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  position: relative;
  margin-bottom: 50px;
}

@media (min-width: 0px) and (max-width: 535px) {
  .main__section__home {
    margin-bottom: 30px;
  }
}

.main__section__home__content {
  z-index: 1;
  margin: 0px 4vw;
  width: 65vw;
}

.main__section__home__content__text {
  margin-top: 50px;
  overflow: hidden;
  color: #5b5b5b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  align-items: flex-start;
}

.main__section__home__content__text__h2 {
  align-self: flex-start;
  position: relative;
  font-family: 'main';
  font-size: 2.6vw;
  font-weight: 400;
  margin-bottom: 2.3em;
}

.main__section__home__content__text__h1 {
  font-family: 'display';
  font-size: 5vw;
  line-height: 120%;
  text-align: start;
  max-width: 65vw;
  margin-bottom: 0.3em;
  margin-top: 20px;
}

.main__section__home__content__text__simple {
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: flex-end;
}

.main__section__home__content__text__simple__h3, .main__section__home__content__text__simple__p {
  font-family: 'main';
}

.main__section__home__content__text__simple__h3 {
  margin-bottom: 50px;
  font-weight: 500;
  font-size: 1.9vw;
  width: 70%;
  align-self: flex-end;
}

.main__section__home__content__text__simple__p {
  font-weight: 400;
  width: 70%;
  font-size: 28px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) and (max-width: 1240px) {
  .main__section__home__content__text__simple__p {
    font-size: 24px;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .main__section__home__content__text__simple__p {
    font-size: 18px;
  }
}

.main__section__home__picture {
  height: 50vw;
  z-index: 0;
  position: absolute;
  right: 0px;
  top: 0px;
}

.main__section__home__picture__img {
  height: 45vw;
}

@media (min-width: 768px) and (max-width: 1240px) {
  .main__section__home__content {
    width: 65vw;
  }
  .main__section__home__content__text__h2 {
    font-size: 2.8vw;
  }
  .main__section__home__content__text__h1 {
    font-size: 6vw;
    max-width: 70vw;
  }
  .main__section__home__content__text__simple__h3, .main__section__home__content__text__simple__p {
    font-family: 'main';
  }
  .main__section__home__content__text__simple__h3 {
    font-weight: 500;
    font-size: 2.5vw;
  }
  .main__section__home__content__text__simple__p {
    font-weight: 400;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .main__section__home__content {
    width: 98vw;
  }
  .main__section__home__content__text {
    align-items: flex-start;
  }
  .main__section__home__content__text__h2 {
    font-size: 3vw;
  }
  .main__section__home__content__text__h1 {
    font-size: 8vw;
    max-width: 90vw;
  }
  .main__section__home__content__text__simple {
    align-self: flex-start;
    width: 80vw;
  }
  .main__section__home__content__text__simple__h3, .main__section__home__content__text__simple__p {
    font-family: 'main';
  }
  .main__section__home__content__text__simple__h3 {
    font-weight: 500;
    font-size: 5vw;
  }
  .main__section__home__content__text__simple__p {
    font-weight: 400;
  }
}

@media (min-width: 0px) and (max-width: 767px) and (min-width: 0px) and (max-width: 535px) {
  .main__section__home__content__text__simple__p {
    width: 70vw;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .main__section__home__picture {
    height: 80vw;
    z-index: 0;
    position: absolute;
    right: -40px;
    top: 0px;
  }
  .main__section__home__picture__img {
    height: 75vw;
    opacity: 0.5;
  }
}

@media (min-width: 0px) and (max-width: 535px) {
  .main__section__home__picture {
    height: 100vw;
    z-index: 0;
    position: absolute;
    right: -40px;
    top: 0px;
  }
  .main__section__home__picture__img {
    height: 90vw;
    opacity: 0.3;
  }
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

.main__section__diagnostic {
  margin-bottom: 2vw;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90vw;
  z-index: 1;
  overflow: visible;
}

.main__section__diagnostic__picture {
  height: 50vw;
  z-index: 0;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.main__section__diagnostic__picture__img {
  height: 25vw;
}

.main__section__diagnostic__speech {
  margin-bottom: -100px;
  display: flex;
  align-items: flex-end;
}

@media (min-width: 0px) and (max-width: 1300px) {
  .main__section__diagnostic__speech {
    margin-bottom: -50px;
  }
}

@media (max-width: 565px) {
  .main__section__diagnostic__speech {
    margin-bottom: 0px;
  }
}

.main__section__diagnostic__speech__question {
  align-self: center !important;
}

.main__section__diagnostic__speechStudent {
  align-self: flex-end;
  display: flex;
  align-items: center;
}

.main__section__diagnostic__speechStudent__answer {
  align-self: center !important;
}

.main__section__diagnostic__answer {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  max-width: 45vw;
  height: fit-content;
  padding: 2rem 1.5rem 1rem 1.5rem;
  left: 0px;
  top: 0px;
  transform-origin: 0px 0px;
  background: linear-gradient(#dedede 0%, #d1d1f7 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  float: left;
  position: relative;
  margin-bottom: 20px;
  z-index: 2;
}

.main__section__diagnostic__answer::after, .main__section__diagnostic__answer::before {
  z-index: 1;
  content: "";
  position: absolute;
  left: 100%;
  bottom: .5em;
  width: 1.8em;
  height: 1.8em;
  border: .5em solid white;
  border-radius: 50%;
  background: linear-gradient(0deg, white 43%, rgba(255, 255, 255, 0) 100%);
}

.main__section__diagnostic__answer::before {
  border-top: none;
  height: .9em;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
  background: #d2d1f4;
  border-color: #d2d1f4;
  margin-left: -.9em;
}

.main__section__diagnostic__answer__text {
  z-index: 5;
  position: relative;
  text-align: start;
  font-family: 'main';
  font-size: 28px;
  color: #474747;
}

.main__section__diagnostic__answer__buttonContainer {
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin: 10px;
  transition: all 0.5s ease-out;
  align-self: flex-end;
  margin-top: 10px;
  z-index: 5;
}

.main__section__diagnostic__answer__buttonContainer:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}

.main__section__diagnostic__answer__buttonContainer__button {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #474747;
  background-color: #F7D939;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  min-height: 1.5vw;
  max-height: fit-content;
  text-transform: none;
  padding: 10px 20px;
  font-size: 24px;
}

.main__section__diagnostic__answer__buttonContainer__button:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

.main__section__diagnostic__answer__box {
  display: flex;
  align-self: flex-end;
  max-width: 45vw;
  height: fit-content;
  padding: 2rem 1.5rem 1rem 1.5rem;
  left: 0px;
  top: 0px;
  transform-origin: 0px 0px;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 30px;
  float: left;
  position: relative;
  margin-bottom: 20px;
}

.main__section__diagnostic__question {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  max-width: 45vw;
  height: fit-content;
  padding: 2rem 1.5rem;
  left: 0px;
  top: 0px;
  transform-origin: 0px 0px;
  background: linear-gradient(#dedede 0%, #d1d1f7 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  position: relative;
  margin-bottom: 20px;
  float: right;
  z-index: 2;
}

.main__section__diagnostic__question::after, .main__section__diagnostic__question::before {
  z-index: 1;
  content: "";
  position: absolute;
  right: 100%;
  bottom: .5em;
  width: 1.8em;
  height: 1.8em;
  border: .5em solid white;
  border-radius: 50%;
  background: linear-gradient(0deg, white 43%, rgba(255, 255, 255, 0) 100%);
}

.main__section__diagnostic__question::before {
  border-top: none;
  height: .9em;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
  background: #d2d1f4;
  border-color: #d2d1f4;
  margin-right: -.9em;
}

.main__section__diagnostic__question__border {
  width: 100%;
  height: 100%;
  right: 0px;
  top: 0px;
  position: absolute;
  transform-origin: 0px 0px;
  mix-blend-mode: color-dodge;
  border: 1.15px solid #d1d1f7;
  border-radius: 30px;
}

.main__section__diagnostic__question__layout {
  width: 99%;
  height: 50%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  transform-origin: 0px 0px;
  mix-blend-mode: color-dodge;
  background: linear-gradient(180deg, #d4d4d454 30%, rgba(235, 235, 235, 0) 100%);
  align-self: center;
  z-index: 1;
}

.main__section__diagnostic__question__text {
  z-index: 5;
  position: relative;
  text-align: start;
  font-family: 'main';
  font-size: 28px;
  color: #474747;
}

.main__section__diagnostic__question__text__time {
  font-weight: 550;
}

.main__section__diagnostic__teacherImg {
  align-self: flex-start;
  height: 20vw;
  min-height: 200px;
  left: 0;
  top: 40%;
  z-index: 3;
}

@media (min-width: 830px) and (max-width: 1240px) {
  .main__section__diagnostic__teacherImg {
    right: 25%;
  }
}

@media (min-width: 701px) and (max-width: 768px) {
  .main__section__diagnostic__teacherImg {
    right: 10%;
  }
}

@media (min-width: 566px) and (max-width: 700px) {
  .main__section__diagnostic__teacherImg {
    right: 2%;
  }
}

@media (max-width: 565px) {
  .main__section__diagnostic__teacherImg {
    display: none;
  }
}

.answerHomepage::after, .answerHomepage::before {
  display: none;
}

@media (max-width: 565px) {
  .main__section__diagnostic {
    margin: 4vw 0vw 2vw 0vw;
  }
}

@media (min-width: 1450px) {
  .main__section__diagnostic {
    margin: 4vw 10vw 2vw 10vw;
  }
  .main__section__diagnostic__answer, .main__section__diagnostic__question {
    max-width: 32vw;
  }
  .main__section__diagnostic__answer__text, .main__section__diagnostic__question__text {
    font-size: 1.4vw;
  }
  .main__section__diagnostic__answer__buttonContainer__button, .main__section__diagnostic__question__buttonContainer__button {
    padding: 0.5vw 1vw;
    font-size: 1.2vw;
  }
}

@media (min-width: 768px) and (max-width: 1240px) {
  .main__section__diagnostic {
    width: 90vw;
  }
  .main__section__diagnostic__answer, .main__section__diagnostic__question {
    max-width: 50vw;
  }
  .main__section__diagnostic__answer__text, .main__section__diagnostic__question__text {
    font-size: 24px;
  }
  .main__section__diagnostic__answer__buttonContainer__button, .main__section__diagnostic__question__buttonContainer__button {
    font-size: 22px;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .main__section__diagnostic {
    width: 90vw;
  }
  .main__section__diagnostic__answer, .main__section__diagnostic__question {
    max-width: 60vw;
    padding: 1.2rem 1.5rem;
  }
  .main__section__diagnostic__answer__text, .main__section__diagnostic__question__text {
    font-size: 18px;
  }
  .main__section__diagnostic__answer__buttonContainer__button, .main__section__diagnostic__question__buttonContainer__button {
    font-size: 16px;
  }
}

@media (min-width: 0px) and (max-width: 500px) {
  .main__section__diagnostic {
    width: 90vw;
  }
  .main__section__diagnostic__answer, .main__section__diagnostic__question {
    max-width: 70vw;
    padding: 1rem 1.5rem;
  }
  .main__section__diagnostic__answer__text, .main__section__diagnostic__question__text {
    font-size: 18px;
  }
  .main__section__diagnostic__answer__buttonContainer__button, .main__section__diagnostic__question__buttonContainer__button {
    font-size: 16px;
  }
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

.main__section__strategies {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 5vw;
}

.main__section__strategies__date {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90vw;
}

.main__section__strategies__date__time {
  font-weight: 550;
}

.main__section__strategies__date__img {
  position: absolute;
  right: 10%;
  height: 90%;
}

.main__section__strategies__text {
  font-family: 'main';
  color: #474747;
  font-size: 36px;
  text-align: center;
  width: 80%;
  margin-bottom: 20px;
  font-size: 36px;
}

@media (min-width: 1450px) {
  .main__section__strategies__text {
    font-size: 40px;
    width: 70vw;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main__section__strategies__text {
    font-size: 30px;
    width: 80vw;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .main__section__strategies__text {
    font-size: 24px;
    width: 98vw;
  }
}

.main__section__strategies__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 80px;
}

.main__section__strategies__wrapper__point {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 180px;
  min-width: 100px;
  height: 180px;
  min-height: 100px;
  padding: 1.5vw;
  border-radius: 4.5vw;
  background: radial-gradient(90.77% 92.39% at 31.09% 10.65%, #FFE399 0%, #FFDA7A 24.76%, #FBD266 77.34%, #F5C850 88.15%, #EFC046 100%);
  box-shadow: 3px 7px 10px rgba(0, 0, 0, 0.25);
  margin: 20px;
  transition: all 0.5s ease-out;
}

.main__section__strategies__wrapper__point:hover {
  transform: scale(1.1);
  transition: all 0.5s ease-out;
}

.main__section__strategies__wrapper__point__text {
  font-family: 'main';
  color: #474747;
  font-size: 1.2vw;
  text-align: center;
  cursor: default;
}

.main__section__strategies__wrapper__point__img {
  width: 50%;
}

.main__section__strategies__dateSubtitle {
  font-family: 'main';
  font-size: 30px;
  color: #474747;
  font-weight: 500;
  text-align: center;
  width: 60vw;
  margin-bottom: 30px;
  z-index: 2;
}

.main__section__strategies__popUp {
  margin: auto;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main__section__strategies__popUp__modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0px;
}

.main__section__strategies__popUp__modal__content {
  max-height: 90vh;
  max-width: 98vw;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.main__section__strategies__popUp__modal__content__slider {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.main__section__strategies__popUp__modal__content__slider__slide {
  height: fit-content;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.main__section__strategies__popUp__modal__content__slider__slide__img {
  padding: 15px 0px;
  max-width: 98vw;
  max-height: calc( 90vh - 30px);
  border-radius: 30px;
  display: block;
  margin: 0 auto;
}

@media (min-width: 1450px) {
  .main__section__strategies__date {
    width: 70vw;
  }
  .main__section__strategies__date__time {
    font-size: 1.2vw;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main__section__strategies__wrapper__point {
    width: 20vw;
    min-width: 150px;
    height: 20vw;
    min-height: 150px;
  }
  .main__section__strategies__wrapper__point__text {
    font-size: 14px;
  }
  .main__section__strategies__date {
    width: 90vw;
  }
  .main__section__strategies__date__time {
    font-size: 22px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .main__section__strategies__wrapper__point {
    margin: 10px;
    width: 15vw;
    min-width: 100px;
    height: 15vw;
    min-height: 100px;
  }
  .main__section__strategies__wrapper__point__text {
    font-size: 14px;
  }
  .main__section__strategies__date {
    width: 90vw;
  }
  .main__section__strategies__date__time {
    font-size: 16px;
  }
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

.main__section__rates {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main__section__rates__title {
  font-family: 'main';
  color: #474747;
  font-weight: 500;
  text-align: center;
  width: 80vw;
  margin-bottom: 40px;
  font-size: 36px;
}

@media (min-width: 1450px) {
  .main__section__rates__title {
    font-size: 40px;
    width: 70vw;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main__section__rates__title {
    font-size: 30px;
    width: 80vw;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .main__section__rates__title {
    font-size: 24px;
    width: 98vw;
  }
}

.main__section__rates__container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

@media (min-width: 0px) and (max-width: 920px) {
  .main__section__rates__container {
    flex-wrap: wrap;
  }
}

.main__section__rates__container .rateArrow {
  display: none;
}

.main__section__rates__container .rateArrow__first {
  display: none;
}

.main__section__rates__container .rateArrow__second {
  display: none;
}

.main__section__rates__container__box {
  max-width: 420px;
  min-width: 320px;
  border-radius: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 20px;
}

@media (min-width: 0px) and (max-width: 920px) {
  .main__section__rates__container__box {
    max-width: 500px;
  }
}

.main__section__rates__container__box__rect1 {
  border-radius: 70px;
  box-shadow: rgba(229, 229, 229, 0.3) 8px 8px 8px 8px;
  background: rgba(229, 229, 229, 0.3);
}

.main__section__rates__container__box__rect1__rect2 {
  border-radius: 70px;
  background: linear-gradient(360deg, #E3E3F8 0%, #E8E8E8 100%);
}

.main__section__rates__container__box__rect1__rect2__rect3 {
  position: relative;
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.884) 6%, rgba(255, 255, 255, 0) 100%);
  box-shadow: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 6%, rgba(255, 255, 255, 0) 100%) 4px 4px 4px;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4 {
  position: relative;
  border-radius: 70px;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5 {
  border-radius: 75px;
  border: 1px #D1D1F7 solid;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6 {
  border-radius: 70px;
  background: rgba(229, 229, 229, 0.3);
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content {
  font-family: 'main';
  color: #474747;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px 25px 30px;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__p {
  font-size: 18px;
  margin-bottom: 5px;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__title {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 15px;
  text-align: center;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__title__note {
  font-size: 14px;
  font-weight: 400;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__duration {
  font-size: 18px;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__img {
  margin: 25px 0px;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list {
  margin-bottom: 20px;
  margin-left: 10px;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  padding-right: 20px;
}

@media (min-width: 426px) and (max-width: 425px) {
  .main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__list__item {
    font-size: 16px;
  }
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__price {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 3px;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__note {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button {
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin: 10px;
  transition: all 0.5s ease-out;
  margin-bottom: 25px;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button__hover {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #474747;
  background-color: #F7D939;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__button__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__details {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: #474747;
  background-image: linear-gradient(90deg, #FF8845 0%, #EFC046 100%);
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  background-size: 0% 100%;
  background-position: 0% 100%;
  transition: background-size 0.5s ease-out, color 0.5s ease-out;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__details:hover {
  color: transparent;
  background-size: 100% 100%;
  text-decoration: underline;
  cursor: pointer;
}

.main__section__rates__container__box__rect1__rect2__rect3__rect4__rect5__rect6__content__details__img {
  margin-left: 10px;
}

.main__section__rates__popUp {
  margin: auto;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main__section__rates__popUp__modal {
  position: relative;
  width: 90vw;
  height: 80vh;
  margin: 0px;
}

.main__section__rates__popUp__modal__content {
  max-height: 80vh !important;
  width: 100% !important;
  margin: 0px !important;
  border-radius: 30px;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;
  padding: 45px 45px 30px 30px;
  position: relative;
  background: linear-gradient(90deg, rgba(255, 201, 60, 0.33) 0%, rgba(255, 201, 60, 0) 100%) !important;
}

.main__section__rates__popUp__modal__content__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main__section__rates__popUp__modal__content__container__chooseRate {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.main__section__rates__popUp__modal__content__container__chooseRate__button {
  font-family: 'main';
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: #6B6B6B;
  border-radius: 30px;
  text-decoration: none;
  color: #fff;
  background-image: linear-gradient(45deg, #f5d06c 50%, #d1d1f700 100%);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  z-index: 2;
  background-position: 0% 100%;
  transition: background-size 0.5s ease-out, color 0.5s ease-out;
}

@media (min-width: 0px) and (max-width: 768px) {
  .main__section__rates__popUp__modal__content__container__chooseRate__button {
    margin-bottom: 10px;
  }
}

.main__section__rates__popUp__modal__content__container__chooseRate__button:hover, .main__section__rates__popUp__modal__content__container__chooseRate__button.active {
  color: #474747;
  background-size: 100% 100%;
  cursor: pointer;
  z-index: 1;
}

.main__section__rates__popUp__modal__content__container__info {
  width: 100%;
  overflow-y: auto;
  padding-right: 20px;
}

.main__section__rates__popUp__modal__content__container__info__rate__box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  box-shadow: 4px 4px 18px rgba(197, 194, 179, 0.9) inset;
  border-radius: 30px;
  padding: 20px 10px;
}

.main__section__rates__popUp__modal__content__container__info__rate__box__title {
  font-family: 'main';
  font-weight: 600;
  color: #474747;
  text-transform: uppercase;
  margin-bottom: 18px;
  text-align: center;
  font-size: 16px;
}

.main__section__rates__popUp__modal__content__container__info__rate__box__features {
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: space-evenly;
}

@media (min-width: 0px) and (max-width: 768px) {
  .main__section__rates__popUp__modal__content__container__info__rate__box__features {
    margin: 0px;
  }
}

.main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer {
  margin: 0px 20px;
  width: 100%;
  margin-bottom: 15px;
}

@media (min-width: 0px) and (max-width: 768px) {
  .main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer {
    margin: 0px 0px 15px 0px;
    width: 98%;
  }
}

.main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer__text {
  color: #474747;
  font-family: 'main';
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@media (min-width: 0px) and (max-width: 1024px) {
  .main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer__text {
    font-size: 14px;
  }
}

.main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer__text .bold {
  font-weight: 700;
  margin-bottom: 7px;
}

.main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer__text .inline {
  min-width: max-content;
  text-align: center;
}

@media (min-width: 0px) and (max-width: 425px) {
  .main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer__text .inline {
    min-width: fit-content;
  }
}

.main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer__text .inlinePremium {
  min-width: fit-content;
  text-align: center;
}

@media (min-width: 0px) and (max-width: 425px) {
  .main__section__rates__popUp__modal__content__container__info__rate__box__features__textContainer__text .inlinePremium {
    min-width: fit-content;
  }
}

.main__section__rates__popUp__modal__content__container__info__rate__box__themes {
  width: 100%;
}

.main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer {
  margin: 0px 20px 15px 20px;
  width: 100%;
  min-width: 200px;
}

@media (min-width: 0px) and (max-width: 768px) {
  .main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer {
    margin: 0px 0px 15px 0px;
  }
}

.main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer__text {
  color: #474747;
  text-align: center;
  font-family: 'main';
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 0px) and (max-width: 1024px) {
  .main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer__text {
    font-size: 14px;
  }
}

.main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer__text .column {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer__text .column__text {
  margin-bottom: 15px;
  width: 80%;
  text-align: justify;
}

@media (min-width: 0px) and (max-width: 1024px) {
  .main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer__text .column__text {
    width: 98%;
    margin: 0px 15px 15px 15px;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .main__section__rates__popUp__modal__content__container__info__rate__box__themes__textContainer__text .column__text {
    text-align: start;
  }
}

.notAvailable {
  display: flex;
  justify-content: center;
  align-items: center;
  filter: blur(3px);
  z-index: 5;
}

.notAvailable__btn {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

.notAvailable__img {
  z-index: 6;
  position: absolute;
  top: 25%;
  left: 15%;
}

@media (min-width: 921px) and (max-width: 1160px) {
  .main__section__rates__container {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 20% 20% 35% 25%;
    grid-template-areas: 'a a b b' 'a a b b' 'c c b b' 'c c b b';
    align-items: end;
    justify-items: center;
  }
  .main__section__rates__container .__rate1 {
    grid-area: a;
  }
  .main__section__rates__container .__rate2 {
    grid-area: b;
    align-self: center;
  }
  .main__section__rates__container .__rate3 {
    grid-area: c;
  }
  .main__section__rates__container .rateArrow {
    display: block;
  }
  .main__section__rates__container .rateArrow__first {
    display: block;
  }
  .main__section__rates__container .rateArrow__second {
    display: block;
  }
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

.main__section__date {
  margin-bottom: 10vw;
  width: 100%;
  margin: 0vw 10vw 0vw 10vw;
  padding-bottom: 4vw;
  display: flex;
  flex-direction: column;
  position: relative;
}

.main__section__date__format {
  position: relative;
  width: fit-content;
  margin-top: 4vw;
}

.main__section__date__format__box {
  position: relative;
  width: 35vw;
}

.main__section__date__format__text {
  padding: 1.5vw 2vw 1vw 1.5vw;
  z-index: 2;
}

.main__section__date__format__text__title {
  font-family: 'display';
  font-size: 30px;
  line-height: 110%;
  color: #474747;
}

.main__section__date__format__text__p {
  text-align: start;
  font-family: 'main';
  font-size: 28px;
  text-align: 125%;
  color: #474747;
}

.main__section__date__time {
  position: relative;
  align-self: flex-end;
}

.main__section__date__time__box {
  position: relative;
  width: 25vw;
}

.main__section__date__time__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1vw;
  text-align: end;
  font-family: 'main';
  font-size: 1.5vw;
  color: #474747;
  text-align: start;
  z-index: 2;
}

.main__section__date__time__text__title {
  font-family: 'display';
  font-size: 30px;
  color: #474747;
  line-height: 90%;
  text-align: end;
}

.main__section__date__time__text__p {
  text-align: end;
  font-family: 'main';
  font-size: 28px;
  padding-right: 1vw;
  color: #474747;
  margin-bottom: 15px;
}

.main__section__date__time__text__notes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: center;
  width: 100%;
}

.main__section__date__time__text__notes__note {
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
}

@media (min-width: 0px) and (max-width: 768px) {
  .main__section__date__time__text__notes__note {
    margin-bottom: 0;
  }
}

@media (min-width: 0px) and (max-width: 472px) {
  .main__section__date__time__text__notes__note {
    padding: 10px 5px;
  }
}

.main__section__date__time__text__notes__note__img {
  padding-right: 5px;
}

.main__section__date__background {
  width: 100vw;
  position: absolute;
  top: -2vw;
}

.main__section__date__backgroundTeachers {
  width: 100vw;
  position: absolute;
  top: 14vw;
  opacity: 0.3;
}

.dateBubble::after, .dateBubble::before {
  display: none;
}

@media (min-width: 0px) and (max-width: 472px) {
  .dateBubble {
    padding: 20px 0px 10px 0px;
    width: fit-content;
    max-width: 99vw;
  }
}

@media (min-width: 768px) and (max-width: 1240px) {
  .main__section__date__format__text__title {
    font-size: 30px;
  }
  .main__section__date__format__text__p {
    font-size: 24px;
  }
  .main__section__date__time__text__title {
    font-size: 30px;
  }
  .main__section__date__time__text__p {
    font-size: 24px;
  }
  .main__section__date__time__text__notes__note {
    font-size: 22px;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .main__section__date__format__text__title {
    font-size: 28px;
  }
  .main__section__date__format__text__p {
    font-size: 18px;
  }
  .main__section__date__time__text__title {
    font-size: 28px;
  }
  .main__section__date__time__text__p {
    font-size: 18px;
  }
  .main__section__date__time__text__notes__note {
    font-size: 18px;
  }
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

.main__section__developers {
  margin: 4vw 10vw 0vw 10vw;
}

.main__section__developers__title {
  font-family: 'display';
  color: #474747;
  font-size: 2.3vw;
  line-height: 120%;
  margin-bottom: 2vw;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

.main__section__teachers {
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding-top: 50px;
  margin-bottom: 50px;
}

.main__section__teachers__title {
  font-family: 'display';
  color: #474747;
  line-height: 120%;
  margin-bottom: 2vw;
  font-size: 42px;
  text-align: center;
}

@media (min-width: 1450px) {
  .main__section__teachers__title {
    font-size: 48px;
    width: 70vw;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main__section__teachers__title {
    font-size: 40px;
    width: 90vw;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .main__section__teachers__title {
    font-size: 32px;
    width: 98vw;
  }
}

.main__section__teachers__container {
  min-height: fit-content;
  max-height: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  min-width: calc( 100vw - 200px);
  max-width: calc( 100vw - 200px);
}

.main__section__teachers__container__slider {
  width: 90vw;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.main__section__teachers__container__slider.swiper-wrapper {
  align-items: center;
}

.main__section__teachers__container__slider__slide {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.main__section__teachers__container__slider__slide .cardDev {
  width: 70%;
}

.main__section__teachers__container__slider__slide__name {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  color: #474747;
  font-size: 18px;
  text-align: center;
  width: 80%;
}

.main__section__teachers__container__slider__slide__card {
  position: relative;
  width: 100%;
  width: 50%;
  margin-bottom: 10px;
}

.main__section__teachers__container__slider__slide__card__img {
  width: 100%;
  border-radius: 30px;
  box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.25);
}

.main__section__teachers__container__slider__slide__card__hover {
  opacity: 0;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 6.77%, rgba(0, 0, 0, 0) 100%);
  border-radius: 30px;
  position: absolute;
  bottom: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  transition: all 0.5s ease-out;
}

.main__section__teachers__container__slider__slide__card__hover:hover {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.main__section__teachers__container__slider__slide__card__hover__container {
  padding: 15px;
  max-height: calc( 100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.main__section__teachers__container__slider__slide__card__hover__container__name {
  color: #fff;
  margin-bottom: 10px;
  font-family: 'main';
  font-size: 16px;
  font-weight: 500;
}

.main__section__teachers__container__slider__slide__card__hover__container__info {
  color: #fff;
  margin-bottom: 5px;
  font-family: 'main';
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 60%;
}

.main__section__teachers__container__slider__slide__card__hover__container__button {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #474747;
  background-color: #F7D939;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-family: 'main';
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  align-self: flex-end;
}

.main__section__teachers__container__slider__slide__card__hover__container__button:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

.main__section__teachers__container__slider__slide__card__hover__container__button__arrow {
  margin-left: 5px;
}

.main__section__teachers__popUp {
  margin: auto;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main__section__teachers__popUp__modal {
  position: relative;
  width: 60vw;
  height: 80vh;
  margin: 0px;
}

.main__section__teachers__popUp__modal__content {
  max-height: 80vh !important;
  width: 100% !important;
  margin: 0px !important;
  border-radius: 30px;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;
  padding: 45px 45px 30px 30px;
  position: relative;
  background: linear-gradient(90deg, rgba(255, 201, 60, 0.33) 0%, rgba(255, 201, 60, 0) 100%) !important;
}

.main__section__teachers__popUp__modal__content__left {
  width: 30%;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main__section__teachers__popUp__modal__content__left__img {
  width: 60%;
  border-radius: 200px;
  margin-bottom: 20px;
}

.main__section__teachers__popUp__modal__content__left__info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main__section__teachers__popUp__modal__content__left__info__name {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  color: #474747;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: center;
}

.main__section__teachers__popUp__modal__content__left__info__post {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  font-size: 14px;
  font-weight: 400;
  color: #636363;
  text-align: center;
}

.main__section__teachers__popUp__modal__content__right {
  width: 60%;
  max-height: 40rem;
  overflow-y: auto;
  padding-right: 20px;
}

.main__section__teachers__popUp__modal__content__right__text {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #474747;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  text-indent: 20px;
}

.window__teacher {
  background-color: #6B6B6B !important;
}

@media (min-width: 0px) and (max-width: 1070px) and (min-width: 0px) and (max-width: 500px) {
  .main__section__teachers__title {
    font-size: 36px;
  }
}

@media (min-width: 0px) and (max-width: 1070px) {
  .main__section__teachers__container {
    min-width: 98vw;
    max-width: 98vw;
  }
}

@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 500px) {
  .main__section__teachers__title {
    font-size: 36px;
  }
}

@media (min-width: 0px) and (max-width: 850px) {
  .main__section__teachers__container {
    min-width: 90vw;
    max-width: 90vw;
  }
}

@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 550px) {
  .main__section__teachers__container__slider .swiper-button-prev {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    left: 10%;
    z-index: 11;
  }
  .main__section__teachers__container__slider .swiper-button-next {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    right: 10%;
    z-index: 11;
  }
}

@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 550px) {
  .main__section__teachers__container__slider__slide__card {
    width: 90%;
  }
}

@media (min-width: 0px) and (max-width: 850px) {
  .main__section__teachers__popUp__modal__content__right__text {
    font-size: 14px;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 1000px) {
  .main__section__teachers__container__slider__slide__card__hover__container__name {
    font-size: 14px;
  }
  .main__section__teachers__container__slider__slide__card__hover__container__info {
    font-size: 12px;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 900px) {
  .main__section__teachers__popUp__modal {
    width: 90vw;
  }
}

@media (min-width: 0px) and (max-width: 1240px) {
  .main__section__teachers__popUp__modal__content {
    flex-direction: column;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 570px) {
  .main__section__teachers__popUp__modal__content {
    padding: 25px 25px 15px 15px;
  }
}

@media (min-width: 0px) and (max-width: 1240px) {
  .main__section__teachers__popUp__modal__content__left {
    margin-right: 0px;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
  }
  .main__section__teachers__popUp__modal__content__left__img {
    width: 20%;
    margin-right: 30px;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 950px) {
  .main__section__teachers__popUp__modal__content__left__img {
    width: 40%;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 900px) {
  .main__section__teachers__popUp__modal__content__left__img {
    width: 30%;
  }
}

@media (min-width: 0px) and (max-width: 1240px) {
  .main__section__teachers__popUp__modal__content__left__info {
    width: 50%;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 570px) {
  .main__section__teachers__popUp__modal__content__left__info__name {
    font-size: 4vw;
  }
  .main__section__teachers__popUp__modal__content__left__info__post {
    font-size: 3vw;
  }
}

@media (min-width: 0px) and (max-width: 1240px) {
  .main__section__teachers__popUp__modal__content__left__info__name {
    text-align: start;
  }
  .main__section__teachers__popUp__modal__content__left__info__post {
    text-align: start;
  }
  .main__section__teachers__popUp__modal__content__right {
    width: 100%;
    max-height: 20rem;
  }
}

@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 570px) {
  .main__section__teachers__popUp__modal__content__right {
    max-height: 30rem;
  }
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

@media (min-width: 1025px) {
  .main__section__additional {
    margin: 4vw 0px 10px 0px;
    position: relative;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-items: flex-start;
  }
  .main__section__additional__box {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    position: relative;
  }
  .main__section__additional__box__button {
    z-index: 2;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    border-radius: 30px;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
    margin: 10px;
    transition: all 0.5s ease-out;
    margin-bottom: 25px;
  }
  .main__section__additional__box__button:hover {
    box-shadow: none;
    transition: all 0.5s ease-out;
  }
  .main__section__additional__box__button__hover {
    cursor: pointer;
    box-shadow: none;
    min-height: 46px;
    width: fit-content;
    padding: 10px 30px;
    color: #474747;
    background-color: #F7D939;
    border-radius: 30px;
    border: none;
    font-family: 'main';
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.5s ease-out;
  }
  .main__section__additional__box__button__hover:hover {
    box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
    transition: all 0.5s ease-out;
  }
  .main__section__additional__box__container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .main__section__additional__box__container__question {
    position: relative;
    align-self: flex-start;
  }
  .main__section__additional__box__container__question__box {
    position: relative;
    width: 40vw;
  }
  .main__section__additional__box__container__question__text {
    position: absolute;
    top: 15%;
    padding: 0.5vw 2vw 1vw 4.2vw;
    text-align: start;
    font-family: 'main';
    font-size: 1.5vw;
    color: #474747;
  }
  .main__section__additional__box__container__img {
    width: 50vw;
  }
  .main__section__additional__box__info {
    width: 40vw;
    position: relative;
  }
}

@media (min-width: 0px) and (max-width: 1024px) {
  .main__section__additional {
    margin: 4vw 0px 10vw 0px;
    position: relative;
    width: 95vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
  }
  .main__section__additional__box {
    width: 95%;
    display: flex;
    flex-direction: column;
  }
  .main__section__additional__box__question {
    position: relative;
    align-self: flex-start;
  }
  .main__section__additional__box__question__box {
    position: relative;
    width: 60vw;
  }
  .main__section__additional__box__question__text {
    position: absolute;
    top: 15%;
    padding: 0.5vw 2vw 1vw 6.5vw;
    text-align: start;
    font-family: 'main';
    font-size: 2.2vw;
    color: #474747;
  }
  .main__section__additional__box__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    align-self: flex-end;
  }
  .main__section__additional__box__container__info {
    width: 70vw;
    z-index: 2;
  }
  .main__section__additional__box__img {
    position: absolute;
    width: 100vw;
    bottom: -10%;
    left: -40%;
    opacity: 0.2;
  }
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

.main__section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.main__section__form {
  margin: 0px auto;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  position: relative;
}

.main__section__form__layout {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  position: relative;
  max-height: 70vh;
  border-radius: 30px;
  padding: 25px 20px 20px;
  background-color: #fff !important;
}

.main__section__form__layout__success {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #fff;
}

.main__section__form__layout__success__title {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

.main__section__form__layout__success__p {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
}

.main__section__form__layout__title {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

.main__section__form__layout__text {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
  width: 80%;
}

.main__section__form__layout__item {
  width: fit-content;
  height: fit-content;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main__section__form__layout__item__label {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 15px;
}

.main__section__form__layout__item__input {
  height: fit-content;
  padding: 10px;
  border-radius: 30px;
  background-color: #f7db3900;
  border: 1px solid #474747;
}

.main__section__form__layout__item__input:focus {
  border: none;
  border: 2px solid #F7D939;
  outline: none;
  background-color: #f7db3921;
}

.main__section__form__layout__item__error {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  font-size: 12px;
  color: #550000;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.main__section__form__layout__button {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #474747;
  background-color: #F7D939;
  border-radius: 30px;
  border: none;
  font-family: 'main';
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  background-color: #F7D939;
  color: #474747;
}

.main__section__form__layout__button:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

.main__section__form__layout__button.disabled {
  background-color: #747474;
  cursor: not-allowed;
}

.main__section__form__layout__button.disabled:hover {
  box-shadow: none;
  cursor: not-allowed;
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

.main__docs__modal__layout {
  border-radius: 30px;
  width: 90vw !important;
  height: 90vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main__docs__modal__layout__content {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px 50px;
  margin: 0px 22px 0px 0px;
  max-height: 80vh !important;
  overflow-y: auto;
  overflow-x: clip;
}

@media (max-width: 768px) {
  .main__docs__modal__layout__content {
    padding: 0px 10px 0px 35px;
  }
}

.main__docs__modal__layout__content__title {
  font-family: 'main';
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  color: #474747;
}

@media (max-width: 670px) {
  .main__docs__modal__layout__content__title {
    font-size: 20px;
  }
}

@media (max-width: 550px) {
  .main__docs__modal__layout__content__title {
    font-size: 18px;
  }
}

.main__docs__modal__layout__content__a {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #D1D1F7;
  text-decoration: none;
  text-align: center;
  margin-bottom: 10px;
  transition: all 0.5s ease-out;
}

.main__docs__modal__layout__content__a:hover {
  text-decoration: underline;
  color: #c18f0d;
  transition: all 0.5s ease-out;
}

.main__docs__modal__layout__content__p {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #474747;
  text-decoration: none;
  text-align: start;
  padding: 0px 50px;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}

@media (max-width: 768px) {
  .main__docs__modal__layout__content__p {
    font-size: 16px;
  }
}

.main__docs__modal__layout__content__ol__li {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  color: #474747;
  text-decoration: none;
  text-align: start;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}

@media (max-width: 768px) {
  .main__docs__modal__layout__content__ol__li {
    font-size: 16px;
  }
}

@media (max-width: 425px) {
  .main__docs__modal__layout__content__ol__li {
    font-size: 14px;
  }
}

.main__docs__modal__layout__content__ol__li__ul__li {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #474747;
  text-decoration: none;
  text-align: start;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}

@media (max-width: 768px) {
  .main__docs__modal__layout__content__ol__li__ul__li {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .main__docs__modal__layout__content__ol__li__ul__li {
    font-size: 12px;
  }
}

.main__docs__modal__layout__content__ol__li__ul__ways {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.main__docs__modal__layout__content__ol__li__ul__ways__img {
  margin-right: 20px;
  width: 150px;
}

@media (max-width: 768px) {
  .main__docs__modal__layout__content__ol__li__ul__ways__img {
    width: 70px;
  }
}

@media (max-width: 425px) {
  .main__docs__modal__layout__content__ol__li__ul__ways__img {
    width: 40px;
  }
}

.main__docs__modal__layout__content__first {
  font-family: 'main';
  font-size: 18px;
  font-weight: 500;
  color: #474747;
  text-decoration: none;
  text-align: start;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}

@media (max-width: 768px) {
  .main__docs__modal__layout__content__first {
    font-size: 16px;
  }
}

@media (max-width: 425px) {
  .main__docs__modal__layout__content__first {
    font-size: 14px;
  }
}

.main__docs__modal__layout__content__second, .main__docs__modal__layout__content__third {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #474747;
  text-decoration: none;
  text-align: start;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}

.main__docs__modal__layout__content__second {
  margin-left: 10px;
}

.main__docs__modal__layout__content__third {
  margin-left: 20px;
}

@media (max-width: 768px) {
  .main__docs__modal__layout__content__second, .main__docs__modal__layout__content__third {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .main__docs__modal__layout__content__second, .main__docs__modal__layout__content__third {
    font-size: 12px;
  }
}

@font-face {
  font-family: 'main';
  src: url(../fonts/Montserrat.ttf);
}

@font-face {
  font-family: 'display';
  src: url(../fonts/Gropled-Bold.otf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #6B6B6B;
}

::-webkit-scrollbar-thumb {
  background-color: #F7D939;
  border-radius: 10px;
}

.visible-border {
  overflow: visible;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}

.footer {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  position: sticky;
  top: 0px;
  z-index: 20;
  background-color: #6B6B6B;
}

.footer__content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 40px 30px 40px;
}

.footer__content__left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}

.footer__content__left__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.footer__content__left__links__link {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-right: 15px;
  transition: all 0.5s ease-out;
}

.footer__content__left__links__link:hover {
  text-decoration: underline;
  color: #F7D939;
  transition: all 0.5s ease-out;
}

.footer__content__left__payments {
  display: flex;
  justify-content: space-evenly;
}

.footer__content__left__payments__payment {
  width: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.footer__content__left__payments__payment:nth-child(5) {
  margin-right: 0px;
}

.footer__content__left__rights {
  font-family: 'main';
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}

.footer__content__right {
  display: flex;
  margin-right: 65px;
}

.footer__content__right__contacts {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.footer__content__right__contacts__link {
  margin-bottom: 5px;
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-right: 15px;
  font-weight: 500;
  transition: all 0.5s ease-out;
}

.footer__content__right__contacts__link:hover {
  text-decoration: underline;
  color: #F7D939;
  transition: all 0.5s ease-out;
}

.footer__content__right__contacts__link:nth-child(2) {
  margin-bottom: 15px;
}

.footer__content__right__contacts__info {
  font-family: 'main';
  font-size: 16px;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}

.footer__content__right__networks {
  display: flex;
  flex-direction: column;
}

.footer__content__right__networks__item {
  width: fit-content;
}

.footer__content__right__networks__item__img {
  width: 50px;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .footer__content__left__links {
    flex-direction: column;
  }
}

@media (min-width: 769px) and (max-width: 1024px) and (min-width: 0px) and (max-width: 1000px) {
  .footer__content__right {
    flex-direction: column;
  }
  .footer__content__right__contacts__info {
    margin-bottom: 10px;
  }
  .footer__content__right__networks {
    flex-direction: row;
  }
  .footer__content__right__networks__item {
    margin-right: 20px;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .footer {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer__content {
    margin: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: fit-content;
  }
  .footer__content__contacts {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .footer__content__contacts__links {
    display: flex;
    flex-direction: column;
  }
  .footer__content__contacts__links__link {
    margin-bottom: 5px;
    font-family: 'main';
    font-size: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    margin-right: 15px;
    font-weight: 500;
    transition: all 0.5s ease-out;
  }
  .footer__content__contacts__links__link:hover {
    text-decoration: underline;
    color: #F7D939;
    transition: all 0.5s ease-out;
  }
  .footer__content__contacts__links__link:nth-child(2) {
    margin-bottom: 15px;
  }
  .footer__content__contacts__networks {
    width: fit-content;
    display: flex;
    justify-content: space-evenly;
    align-self: center;
    flex-direction: column;
  }
  .footer__content__contacts__networks__item__img {
    width: 30px;
  }
  .footer__content__docs {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .footer__content__docs__link {
    font-family: 'main';
    font-size: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    margin-right: 15px;
    transition: all 0.5s ease-out;
  }
  .footer__content__docs__link:hover {
    text-decoration: underline;
    color: #F7D939;
    transition: all 0.5s ease-out;
  }
  .footer__content__payments {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  .footer__content__payments__payment {
    width: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .footer__content__payments__payment:nth-child(5) {
    margin-right: 0px;
  }
  .footer__content__info {
    font-family: 'main';
    font-size: 16px;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    margin-bottom: 10px;
  }
  .footer__content__rights {
    font-family: 'main';
    font-size: 10px;
    font-weight: 300;
    color: #fff;
  }
}

* {
  margin: 0;
  padding: 0;
}

*::selection {
  color: #ffe5a2;
  background-color: #364169;
}

body, #jcont {
  cursor: url(../img/cursor.svg), auto;
}

a, button {
  cursor: url(../img/cursor-pointer.svg), pointer !important;
}

#pointer {
  cursor: url(../img/cursor-pointer.svg), pointer !important;
}

p {
  cursor: url(../img/cursor.svg), text !important;
}

.hoverl_aaab, .label_c47e, ._bottom_fce6, .hoverBox_f714, .cssLeaf_a718, .leaf_e3ab, .text_b99f, .omnichannel_c682, .logoIcon_fe12, .copyright_f06a, .logoIcon_fe12, .text_b99f, .link_c189, .copy_d454, ._bottom_e45a, .__ru_e9fe, .__show_bcd8, .omnichannel_c682 .bottom_e414, #jivo-iframe-container {
  cursor: url(../img/cursor-pointer.svg), pointer !important;
}

.hystmodal__window {
  background-color: #ffffff !important;
  border-radius: 30px;
}
